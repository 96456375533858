"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaPhone,
  FaEnvelope,
  FaMapMarker,
  FaYoutube,
  FaDiscord,
} from "react-icons/fa";
import styled from "styled-components";

interface FooterProps {
  // Add any props here if needed
}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className=" text-neutral-600 p-0">
      <div className="bg-neutral-200 p-2 md:p-10 text-xs md:text-base">
        <div className="max-w-6xl mx-auto flex flex-wrap md:gap-y-10 md:gap-x-6 items-end md:space-x-5 justify-evenly md:justify-evenly">
          {/* Section 1: Logo and Text */}
          <div className="flex flex-col items-center md:items-start w-full md:w-fit text-center md:text-left flex-wrap mb-5 md:mb-0">
            <div className="text-white text-4xl">
              <Image
                src="/files/Logo V1.svg"
                alt="Logo"
                width={190}
                height={48}
              />
            </div>
            {/* <p className="mb-4">Die TCG Events Plattform</p> */}
            <div className="flex space-x-4 mt-5">
              {/* <FaFacebook className="cursor-pointer" />
              <FaTwitter className="cursor-pointer" /> */}
              <Link href="https://www.instagram.com/tcgevents_net">
                <FaInstagram className="cursor-pointer" />
              </Link>
              <Link href="https://www.youtube.com/@TCGevents">
                <FaYoutube className="cursor-pointer" />
              </Link>
              <Link href="https://discord.gg/VQU3f7Jf7K">
                <FaDiscord className="cursor-pointer" />
              </Link>
              {/* <FaLinkedin className="cursor-pointer" /> */}
            </div>
            <div className="mt-1">
              © 2024 TCGevents. Alle Rechte vorbehalten
            </div>
          </div>

          {/* Section 2: Footer Links */}
          <div className="flex md:justify-center">
            <div className="flex flex-col md:text-center lext-left">
              <a href="/agb">Allgemeine Geschäftsbedingungen</a>
            </div>
          </div>

          {/* Section 3: Contact Info */}
          <div className="flex md:justify-center">
            <div className="flex flex-col md:text-center text-left">
              <a href="/datenschutz">Datenschutzerklärung</a>
            </div>
          </div>

          <div className="flex md:justify-center">
            <div className="flex flex-col md:text-center text-left">
              <a href="/impressum">Impressum</a>
            </div>
          </div>

          <div className="flex md:justify-center">
            <div className="flex flex-col md:text-center text-left">
              <a href="/apply">Veranstalter werden</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

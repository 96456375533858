import { create } from "zustand";

interface SepaDebit {
  last4: string;
  bank_code: string;
}

interface BankAccount {
  id: number;
  bankName: string;
  accountNumber: string;
  sepa_debit: SepaDebit;
}

interface Address {
  city: string | null;
  country: string;
  line1: string | null;
  line2: string | null;
  postal_code: string | null;
  state: string | null;
}

interface BillingDetails {
  address: Address;
  email: string;
  name: string;
  phone: string | null;
}

interface CardChecks {
  address_line1_check: string | null;
  address_postal_code_check: string | null;
  cvc_check: string;
}

interface Networks {
  available: string[];
  preferred: string | null;
}

interface ThreeDSecureUsage {
  supported: boolean;
}

interface Card {
  brand: string;
  checks: CardChecks;
  country: string;
  display_brand: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  generated_from: string | null;
  last4: string;
  networks: Networks;
  three_d_secure_usage: ThreeDSecureUsage;
  wallet: string | null;
}

interface CreditCard {
  id: string;
  object: string;
  allow_redisplay: string;
  billing_details: BillingDetails;
  card: Card;
  created: number;
  customer: string;
  livemode: boolean;
  metadata: Record<string, unknown>;
  type: string;
}

interface Wallet {
  bankAccounts: BankAccount[];
  creditCards: CreditCard[];
}

interface Notifications {
  newsletter: boolean;
  tournamentUpdates: boolean;
}

interface UserStore {
  userData: any;
  follows: any[];
  isLoaded: boolean;
  games: any[];
  myGames: any[];
  myGamesLoaded: boolean;
  notifications: Notifications;
  notificationsLoaded: boolean;
  wallet: Wallet | null;
  walletLoaded: boolean;
  setUserData: (data: any) => void;
  setFollows: (data: any) => void;
  setGames: (data: any) => void;
  setMyGames: (data: any) => void;
  setNotifications: (data: Notifications) => void;
  setWallet: (data: Wallet) => void;
  setWalletLoaded: (data: boolean) => void;
}

const useUserStore = create<UserStore>((set) => ({
  userData: null,
  follows: [],
  isLoaded: false,
  games: [],
  myGames: [],
  myGamesLoaded: false,
  notifications: {
    newsletter: false,
    tournamentUpdates: false,
  },
  notificationsLoaded: false,
  wallet: null,
  walletLoaded: false,
  setUserData: (data) => set({ userData: data, isLoaded: true }),
  setFollows: (data) => set({ follows: data }),
  setGames: (data) => set({ games: data }),
  setMyGames: (data) => set({ myGames: data, myGamesLoaded: true }),
  setNotifications: (data) =>
    set({ notifications: data, notificationsLoaded: true }),
  setWallet: (data) => set({ wallet: data, walletLoaded: true }),
  setWalletLoaded: (data) => set({ walletLoaded: data }),
}));

export default useUserStore;

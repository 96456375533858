// ClientProvider.tsx
"use client";

import fetcher from "@/lib/fetcher";
import { Amplify } from "aws-amplify";
import { getCurrentUser } from "aws-amplify/auth";
import { createContext, useState, useEffect } from "react";
import { SWRConfig } from "swr";

export const AuthContext = createContext({
  user: null as string | null, // Explicitly define the type
  checkUser: async () => {},
  setUser: (user: string | null) => {}, // Define the setUser type
});

export default function ClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<string | null>(null); // Explicit type annotation
  const updateUser = (username: string | null) => {
    setUser(username);
  };

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const username = (await getCurrentUser()).username;
      console.log(username);
      setUser(username);
    } catch (error) {
      setUser(null);
    }
  }

  const globalFetcher = ([path, method, withAuth, queryParams]: [
    string,
    "GET" | "POST" | "PUT" | "DELETE",
    boolean,
    Object?,
  ]) => {
    return fetcher({ method, path, withAuth, queryParams });
  };

  return (
    <>
      <AuthContext.Provider value={{ user, checkUser, setUser }}>
        <SWRConfig
          value={{
            fetcher: globalFetcher,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshInterval: 0,
            dedupingInterval: 60000, // 60 Sekunden
          }}
        >
          {children}{" "}
        </SWRConfig>
      </AuthContext.Provider>
    </>
  );
}
